import Script from 'next/script';
import { stripIndent } from 'common-tags';
import { paramStringFromObject } from './util';

const sharedData = { gaPropertyId: `${process.env.NEXT_PUBLIC_GA_ID}` };
const sharedParams = {};

const paramsString = () => {
  if (!sharedParams || Object.keys(sharedParams).length === 0) return '';
  return paramStringFromObject(sharedParams);
};

export const pageView = (url) => {
  try {
    const pageEvent = {
      event: 'pageview',
      page: url,
    };
    window && window.dataLayer && window.dataLayer?.push(pageEvent);
  } catch (e) {}
  return;
};

export const sendEvent = (eventObj) => {
  try {
    window && window.dataLayer && window.dataLayer?.push(eventObj);
  } catch (e) {}

  return;
};

export const dataLayer = () => {
  const data = Object.keys(sharedData).length > 0 ? JSON.stringify(sharedData) : '';
  return (
    <Script
      id='gtm-datalayer'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            window.dataLayer = window.dataLayer || [${data}];
          `,
      }}
    />
  );
};

export const script = () => {
  return (
    <Script
      id='gtm-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src="https://load.ss.hiredigital.com/5ubfqqknfq.js?"+i;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','ez32rry7=aWQ9R1RNLTVIVE5QRFE%3D&page=1');`,
      }}
    />
  );
};

export const noscript = () => {
  return (
    <noscript
      key='gtm-noscript'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            <iframe src="https://load.ss.hiredigital.com/ns.html?id=GTM-5HTNPDQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `,
      }}
    />
  );
};
